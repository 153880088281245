<template>
  <VCard>
    <template #content>
      <div class="flex">
        <div class="font-medium text-base mx-auto">
          {{ selectedMonth }}
        </div>
      </div>

      <div class="grid grid-cols-7 gap-4 mt-5 text-center">
        <div v-for="weekday in weekdays" :key="weekday" class="font-medium">
          {{ weekday }}
        </div>

        <div
          v-for="day in days"
          :key="day.date"
          class="py-0.5 rounded relative"
          :class="[
            { 'bg-theme-1 dark:bg-theme-1 text-white': isToday(day) },
            { 'bg-theme-21 dark:bg-theme-21 text-white': isMarked(day) },
            { 'text-gray-600': !day.isCurrentMonth }
          ]"
        >
          {{ getDate(day.date) }}
        </div>
      </div>

      <slot>
        <div
          v-if="markers.length"
          class="border-t border-gray-200 dark:border-dark-5 mt-5 pt-5 px-1"
        >
          <div
            v-for="(marker, index) in markers"
            :key="index"
            class="flex items-center"
            :class="{ 'mt-4': index !== 0 }"
          >
            <slot name="marker" :marker="marker">
              <div class="w-2 h-2 bg-theme-21 rounded-full mr-3" />

              <span class="truncate">
                {{ marker.name }}
              </span>

              <div
                class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
              />

              <span class="font-medium xl:ml-auto">
                {{ getFullDate(marker.date) }}
              </span>
            </slot>
          </div>
        </div>
      </slot>
    </template>
  </VCard>
</template>

<script>
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
// Components
import VCard from "@/components/VCard";
// Constants
import dateFormats from "@/constants/dateFormats";

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

const WEEKDAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export default {
  components: {
    VCard
  },
  props: {
    markers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedDate: dayjs()
    };
  },
  computed: {
    selectedMonth() {
      var d = new Date();
      return MONTHS[d.getMonth()] + " " + d.getFullYear();
    },
    weekdays() {
      return WEEKDAYS;
    },
    days() {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays
      ];
    },
    today() {
      return dayjs().format("YYYY-MM-DD");
    },
    month() {
      return Number(this.selectedDate.format("M"));
    },
    year() {
      return Number(this.selectedDate.format("YYYY"));
    },
    numberOfDaysInMonth() {
      return dayjs(this.selectedDate).daysInMonth();
    },
    currentMonthDays() {
      return [...Array(this.numberOfDaysInMonth)].map((day, index) => {
        return {
          date: dayjs(`${this.year}-${this.month}-${index + 1}`).format(
            "YYYY-MM-DD"
          ),
          isCurrentMonth: true
        };
      });
    },
    previousMonthDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(
        this.currentMonthDays[0].date
      );

      const previousMonth = dayjs(`${this.year}-${this.month}-01`).subtract(
        1,
        "month"
      );

      // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday - 1
        : 6;

      const previousMonthLastMondayDayOfMonth = dayjs(
        this.currentMonthDays[0].date
      )
        .subtract(visibleNumberOfDaysFromPreviousMonth, "day")
        .date();

      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map(
        (day, index) => {
          return {
            date: dayjs(
              `${previousMonth.year()}-${previousMonth.month() +
                1}-${previousMonthLastMondayDayOfMonth + index}`
            ).format("YYYY-MM-DD"),
            isCurrentMonth: false
          };
        }
      );
    },
    nextMonthDays() {
      const lastDayOfTheMonthWeekday = this.getWeekday(
        `${this.year}-${this.month}-${this.currentMonthDays.length}`
      );

      const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, "month");

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 7 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday;

      return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
        return {
          date: dayjs(
            `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`
          ).format("YYYY-MM-DD"),
          isCurrentMonth: false
        };
      });
    },
    markersDates() {
      return this.markers.map(marker => {
        return dayjs(marker.date).format("YYYY-MM-DD");
      });
    }
  },
  methods: {
    getWeekday(date) {
      return dayjs(date).weekday();
    },
    getDate(day) {
      return dayjs(day).format("D");
    },
    getFullDate(date) {
      return dayjs(date).format(dateFormats.HUMAN_DATE_FORMAT);
    },
    isToday(day) {
      return day.date === dayjs().format("YYYY-MM-DD");
    },
    isMarked(day) {
      return this.markersDates.includes(day.date);
    }
  }
};
</script>
